<template>
  <div class="login">
    <div class="login-box">
      <div class="login-content">
        <div class="login-content-title clearfix">
          <div class="login-content-title-item">密码登录</div>
        </div>
        <div class="login-input-box pt-re">
          <input type="text" placeholder="输入账号" class="pt-ab" v-model="account" ref="username" @blur="usernameOut" @focus="usernameFocus">
          <span class="error pt-ab" v-if="errUsername">格式有误</span>
        </div>
        <div class="login-input-box pt-re">
          <input type="password" placeholder="输入密码" class="pt-ab" @keyup.enter="submit"  v-model="password" @blur="psdOut"  @focus="psdFocus">
          <span class="error pt-ab" v-if="errPsd">账号或密码错误</span>
        </div>
        <div class="btn login-btn"  @click="submit" :class="canSubmit ? '' : 'canUse'">{{submitText}}</div>
      </div>
    </div>
    <Verificate :isShow="showCode" @success="successVer()" @cancel="cancleVer()"></Verificate>
  </div>

</template>
<script>
// import util from '@/utils/util'
import md5 from 'js-md5'
import Qs from 'qs'
import { LoginAdminLogin } from '../../api/http'
import Verificate from '../../components/verificate/Verificate'
const Base64 = require('js-base64').Base64
export default {
  name: 'Login',
  data () {
    return {
      account: '',
      password: '',
      errUsername: false,
      errPsd: false,
      errMa: false,
      submitText: '登录',
      canSubmit: true,
      showCode: false
    }
  },
  components: {
    Verificate
  },
  mounted () {
    // window.localStorage.setItem('token', '')
    // if (window.localStorage.getItem('token')) {
    //   this.$router.push({ path: '/admin' })
    // }
    this.$refs.username.focus()
  },
  methods: {
    // 密码登录提交
    submit () {
      if (!this.canSubmit) {
        return
      }
      if (!this.account) {
        this.errUsername = true
        return
      }
      if (!this.password) {
        this.errPsd = true
        return
      }
      this.canSubmit = false
      this.getSlideCode()
    },
    // 以下是input焦点和失去焦点方法 + input下面提示方法
    usernameOut () {
      if (this.account) {
        this.errUsername = false
      } else {
        this.errUsername = true
      }
    },
    usernameFocus () {
      this.errUsername = false
    },
    psdOut () {
      if (this.password.length === 0) {
        this.errPsd = true
      } else {
        this.errPsd = false
      }
    },

    psdFocus () {
      this.errPsd = false
    },
    getSlideCode () {
      this.canSubmit = true
      this.showCode = true
    },
    successVer () {
      const that = this
      that.userPwsLogin()
      setTimeout(() => {
        that.showCode = false
      }, 100)
    },
    cancleVer () {
      this.showCode = false
    },
    // 账号密码登录
    userPwsLogin () {
      this.submitText = '登录中...'
      const token = 'Basic ' + Base64.encode(this.account + ':' + md5(this.password))
      window.localStorage.setItem('token', token)
      LoginAdminLogin(Qs.stringify({ ltype: 'manager' })).then(resp => {
        this.submitText = '登录'
        this.canSubmit = true
        if (resp.code === '000') {
          this.errPsd = false
          this.$message({ message: '登录成功', type: 'success' })
          window.localStorage.setItem('token', resp.result.tokenType + ' ' + resp.result.accessToken)
          window.localStorage.setItem('loginName', resp.result.user.loginName)
          this.$router.push({ path: '/admin' })
        } else {
          window.localStorage.setItem('token', '')
          this.$message({ message: resp.msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
        this.submitText = '登录'
        window.localStorage.setItem('token', '')
        this.canSubmit = true
        this.errPsd = true
      })
    }
  }
}
</script>
<style lang="less" scoped>
.getMaBtn{
  width: 31%;
  height: 45px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #0064ff, #009dff);
  cursor: pointer;
  line-height: 45px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
}
.getMaBtn:hover {
  opacity: 0.8;
}
.operationBtn{
  color: #2279ff;
  padding: 5px 8px;
  cursor: pointer;
}
.pt-re {
  position: relative;
}
.pt-ab {
  position: absolute;
}
.login {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/image/login-bg.png");
  background-position: center center;
  background-size: 100% 100%;
  .login-box {
    padding-top: 100px;
    width: 450px;
    margin: 0 auto;
    .login-content {
      margin-top: 45px;
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 35px 30px 50px 30px;
      box-shadow: 0 1px 5px 2px #e2f3fd;
      .login-content-title {
        font-size: 15px;
        line-height: 50px;
        .login-content-title-item{
          float: left;
          color: #20343f;
          font-size: 25px;
        }
      }
      .login-input-box {
        width: 100%;
        height: 45px;
        margin-top: 30px;
        input {
          width: 100%;
          height: 45px;
          border-radius: 6px;
          font-size: 18px;
          padding: 0 10px;
          border: 1px solid #e7e7e7;
          background-color: #fff;
          top: 0;
          left: 0;
        }
        input:focus {
          border-color: #007fe1;
        }
        input:hover {
          border-color: #007fe1;
        }
      }
    }
  }
  .login-btn {
    width: 100%;
    height: 48px;
    background-image: linear-gradient(to right, #0064ff, #009dff);
    color: #fff;
    font-size: 18px;
    text-align: center;
    border-radius: 6px;
    margin-top: 40px;
    line-height: 48px;
    opacity: 1
  }
  .login-btn.canUse{
    opacity: 0.8;
  }
  .login-btn:hover {
    opacity: 0.8;
  }
  .error {
    color: red;
    top: 45px;
    left: 5px;
    font-size: 13px;
  }
}
.code-box {
  width: 300px;
  height:150px;
  position: relative;
  .bgc-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .tar-img {
    display: inline-block;
    position: absolute;
    left: 0;
    width: auto;
    height: auto;
    z-index: 1001;
  }
}
</style>
