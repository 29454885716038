<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="show"
      :close-on-click-modal="false"
    >
      <div v-loading="isLoading">
        <div class="close" @click="cancel()">
          <i class="el-icon-close" style="font-size: 20px;color: #ffffff;"></i>
        </div>
        <div class="code-box">
          <img  class="bgc-img" :src="backgroundImg" alt="">
          <img  class="tar-img" :src="targetImg" alt="" :style="{top: targetHeight+'px'}">
        </div>
        <div class="drag" ref="dragDiv">
          <div class="drag_bg"></div>
          <div class="drag_text">{{confirmWords}}</div>
          <div ref="moveDiv" @mousedown="mousedownFn($event)" :class="{'handler_ok_bg':confirmSuccess}" class="handler handler_bg" style="position: absolute;top: 1px;left: 0px;"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/**
 * <Verificate :isShow="showCode" @success="successVer()" @cancel="cancleVer()"></Verificate>
 */
import { GetSliderCode, CheckSliderCode } from '@/api/http'
export default {
  name: 'Verificate',
  props: {
    isShow: {
      required: false
    }
  },
  data () {
    return {
      isLoading: false,
      show: false,
      vid: '',
      backgroundImg: '',
      targetImg: '',
      targetHeight: '',
      beginClientX: 0, /* 距离屏幕左端距离 */
      mouseMoveStata: false, /* 触发拖动状态  判断 */
      maxwidth: '260', /* 拖动最大宽度，依据滑块宽度算出来的 */
      confirmWords: '拖动滑块验证', /* 滑块文字 */
      confirmSuccess: false /* 验证成功判断 */
    }
  },
  methods: {
    getSlideCode () {
      this.isLoading = true
      GetSliderCode().then(resp => {
        this.isLoading = false
        this.backgroundImg = 'data:image/jpg;base64,' + resp.result.backImage
        this.targetImg = 'data:image/png;base64,' + resp.result.slidingImage
        this.targetHeight = resp.result.yHeight
        this.vid = resp.result.id
        this.showCode = true
        document.getElementsByTagName('html')[0].addEventListener('mousemove', this.mouseMoveFn)
        document.getElementsByTagName('html')[0].addEventListener('mouseup', this.moseUpFn)
      })
    },
    mousedownFn: function (e) {
      document.getElementsByClassName('drag_bg')[0].style.backgroundColor = '#e8e8e8'
      document.getElementsByClassName('handler')[0].classList.remove('action')
      document.getElementsByClassName('drag_bg')[0].classList.remove('action')
      document.getElementsByClassName('tar-img')[0].classList.remove('action')
      if (!this.confirmSuccess) {
        e.preventDefault && e.preventDefault() // 阻止文字选中等 浏览器默认事件
        this.mouseMoveStata = true
        this.beginClientX = e.clientX
      }
    }, // mousedoen 事件
    successFunction () {
      this.confirmSuccess = true
      this.confirmWords = '验证通过'
      document.getElementsByClassName('drag_bg')[0].style.backgroundColor = '#7ac23c'
      if (window.addEventListener) {
        document.getElementsByTagName('html')[0].removeEventListener('mousemove', this.mouseMoveFn)
        document.getElementsByTagName('html')[0].removeEventListener('mouseup', this.moseUpFn)
      } else {
        document.getElementsByTagName('html')[0].removeEventListener('mouseup', () => {})
      }
      document.getElementsByClassName('drag_text')[0].style.color = '#fff'
      // document.getElementsByClassName('handler')[0].style.left = this.maxwidth + 'px'
      // document.getElementsByClassName('drag_bg')[0].style.width = this.maxwidth + 'px'
    }, // 验证成功函数
    mouseMoveFn (e) {
      if (this.mouseMoveStata) {
        const width = e.clientX - this.beginClientX
        if (width > 0 && width <= this.maxwidth) {
          document.getElementsByClassName('handler')[0].style.left = width + 'px'
          document.getElementsByClassName('tar-img')[0].style.left = width + 'px'
          document.getElementsByClassName('drag_bg')[0].style.width = width + 'px'
        }
      }
    }, // mousemove事件
    moseUpFn (e) {
      this.mouseMoveStata = false
      var width = e.clientX - this.beginClientX
      if (width < this.maxwidth) {
        CheckSliderCode({ id: this.vid, valCode: parseInt(width) }).then(resp => {
          if (resp.code === '000') {
            this.successFunction()
            this.$emit('success')
            // this.cancel()
          } else {
            document.getElementsByClassName('handler')[0].classList.add('action')
            document.getElementsByClassName('drag_bg')[0].classList.add('action')
            document.getElementsByClassName('tar-img')[0].classList.add('action')
            document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
            document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
            document.getElementsByClassName('tar-img')[0].style.left = 0 + 'px'
            this.getSlideCode()
          }
        })
      } else {
        document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
        document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
        document.getElementsByClassName('tar-img')[0].style.left = 0 + 'px'
      }
    },
    cancel () {
      this.backgroundImg = ''
      this.targetImg = ''
      this.confirmWords = '拖动滑块验证'
      this.mouseMoveStata = false
      this.confirmSuccess = false
      document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
      document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
      document.getElementsByClassName('tar-img')[0].style.left = 0 + 'px'
      document.getElementsByClassName('drag_bg')[0].style.backgroundColor = '#e8e8e8'
      this.$emit('cancel')
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.show = val
        this.backgroundImg = ''
        this.targetImg = ''
        this.confirmWords = '拖动滑块验证'
        this.mouseMoveStata = false
        this.confirmSuccess = false
        this.getSlideCode()
      } else {
        this.show = val
        this.$forceUpdate()
        // this.backgroundImg = ''
        // this.targetImg = ''
        // this.confirmWords = '拖动滑块验证'
        // this.mouseMoveStata = false
        // this.confirmSuccess = false
        document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
        document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
        document.getElementsByClassName('tar-img')[0].style.left = 0 + 'px'
        document.getElementsByClassName('drag_bg')[0].style.backgroundColor = '#e8e8e8'
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  width: 320px;
  margin-top: 30vh!important;
  padding: 10px;
  border-radius: 8px;
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  width: 300px;
  padding: 0;
}
.code-box {
  width: 300px;
  height:150px;
  position: relative;
  .bgc-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .tar-img {
    display: inline-block;
    position: absolute;
    left: 0;
    width: auto;
    height: auto;
    z-index: 1001;
  }
}
.drag-box {
  position: relative;
  background: #e8e8e8;
  width: 300px;
  height: 33px;
  // border: 2px solid #e8e8e8;
  .drag-bg {
    position: absolute;
    background-color: #7ac23c;
    width: 0px;
    height: 100%;
  }
  .drag-text {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 29px;
    user-select: none;
    -webkit-user-select: none;
  }
  .drag-handler-bg {
    position: absolute;
    width: 40px;
    height: 100%;
    cursor: move;
    background: #fff no-repeat center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==");
  }
}
.drag{
  position: relative;
  background-color: #e8e8e8;
  width: 300px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.handler{
  width: 40px;
  height: 32px;
  border: 1px solid #ccc;
  cursor: move;
}
.handler_bg{
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==") no-repeat center;
}
.handler_ok_bg{
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==") no-repeat center;
}
.drag_bg{
  // background-color: #7ac23c;
  background-color: #e8e8e8;
  height: 34px;
  width: 0px;
}
.drag_text{
  position: absolute;
  top: 0px;
  width: 100%;text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select:none;
  -ms-user-select:none;
}
.action {
  transition: all 0.25s;
}
.close {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -40px;
  right: -40px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  .iconfont {
    color: #fff;
  }
}
</style>
